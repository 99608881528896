type VideoData = {
    width: number,
    height: number,
    ratio?: number,
};
type SizeData = {
    width: number,
    height: number,
    scale: number,
};

const finalWidth: number = 720;
const finalHeight: number = 1280;
const finalRatio: number = finalWidth / finalHeight;

export const useVideoResize = () => {
    let currentView: VideoData;
    let currentVideo: VideoData;
    let lastSize: SizeData;

    return {
        get: (view: VideoData, video: VideoData): SizeData => {
            if (view.ratio === undefined) {
                view.ratio = view.width / view.height;
            }
            if (video.ratio === undefined) {
                video.ratio = video.width / video.height;
            }

            if (
                currentVideo !== undefined && currentView !== undefined &&
                currentVideo.height === video.height && currentVideo.width === video.width &&
                currentView.height === view.height && currentView.width === view.width
            ) {
                return lastSize;
            }

            const size: SizeData = {
                width: 0,
                height: 0,
                scale: 1,
            };
            if (video.ratio === finalRatio) {
                if (view.width > video.width) {
                    size.width = video.width;
                    size.height = video.height;
                } else {//TODO: Проверить
                    size.width = view.width;
                    size.height = view.height;
                }
            } else {
                size.width = Math.ceil(finalRatio * video.height);
                size.height = video.height;
            }

            if (size.width !== view.width) {
                size.scale = view.ratio > finalRatio
                    ? view.height * 100 / size.height / 100
                    : view.width * 100 / size.width / 100;
            }

            lastSize = size;
            return size;
        }
    };
}
